@import '../../app/assets/styles/mixins';

.root {
  z-index: 110;
}

.popup {
  &__block {
    position: fixed;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    background: #0c0c0c;
    display: flex;
    max-width: 1000px;
    width: 100%;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  &__promo {
    width: 50%;
    background: linear-gradient(21deg, #4790FE 1.89%, #AA37D2 98.15%);
    padding: 40px;
    text-align: left;

    h2 {
      color: var(--Content-White, #FFF);
      font-size: 30px;
      font-weight: 700;
      margin-bottom: 24px;
      line-height: 40px;
      width: 80%;
    }

    p {
      color: var(--Content-Primary, #EBEBEB);
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 38px;
      width: 65%;
    }
  }



  &__form {
    width: 50%;
    padding: 40px;

    button {
      width: 100%;
    }

    form {
      width: 100%;
    }

    &__icon {
      width: 96px;
      height: 96px;
      border-radius: 24px;
      background: var(--Background-Surface, rgba(166, 174, 203, 0.1));
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 16px auto 24px;
      user-select: none;
      @media (max-width: 768px) {
        width: 80px;
        height: 80px;
      }
      img {
        width: 48px;
        height: 48px;
        user-select: none;
        pointer-events: none;
        @media (max-width: 768px) {
          width: 40px;
          height: 40px;
        }
      }
    }

    &__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;

      h2 {
        color: var(--Content-White, #FFF);
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        margin-bottom: 8px;
      }

      p {
        color: var(--Content-Secondary, #CCC);
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        max-width: 250px;
      }
    }

    &__actions {
      display: flex;
      gap: 12px;
      justify-content: center;
      margin-top: 8px;
    }

    &__support {
      width: 100%;
      gap: 8px;
    }

    &__notion {
      display: block;

      margin-top: 24px;
      color: var(--Content-Tertiary, #a3a3a3);
      text-align: center;

      font-size: 13px;
      a {
        display: inline-flex;
        white-space: nowrap;
        color: var(--Content-Primary, #ebebeb);
        margin-left: 5px;

        font-size: 13px;
        font-weight: 600;
        line-height: 18px;
        transition: all 0.3s ease;
        &:hover {
          opacity: 0.7;
        }
        &:not(:last-child) {
          margin-right: 5px;
        }
      }
    }
  }

  &__bg {
    position: fixed;
    top: -70%;
    left: 75%;
    transform: translateX(-50%);
    max-width: 800px;
    width: 100vw;
    height: 140%;
    border-radius: 50%;
    z-index: -1;
    background: rgba(129, 127, 183, 0.1);
    filter: blur(5rem);
  }

  @media (max-width: 768px) {
    &__promo {
      display: none;
    }

    &__form {
      width: 100%;
    }

    &__bg {
      left: 50%;
    }
  }

  @media (max-width: 512px) {
    &__form {
      padding: 20px;
      &__actions {
        flex-direction: column-reverse;
      }
    }
  }

  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    user-select: none;
    outline: none;
  }
}

.backdrop {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 100;
}
