@import '../../app/assets/styles/mixins';

.root {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  filter: blur(40px);
  transition: opacity 1s ease;
  opacity: 0;

  @media (max-width: 768px) {
    background-size: cover;
    padding: 0;
  }
}

