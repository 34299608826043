@import '../../app/assets/styles/mixins';
.root {
  padding: 72px 0;
  border-top: 1px solid rgba(165, 173, 202, 0.28);
  @media (max-width: 768px) {
    display: none;
  }
}

.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.navigaton {
  display: flex;
  gap: 32px;

  &__link {
    color: #ebebeb;

    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    transition: all 0.3s ease;
    white-space: nowrap;
    &:hover {
      transition: all 0.3s ease;
      opacity: 0.7;
    }
  }
}

.footer {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    @media (max-width: 1200px) {
      flex-direction: column;
      gap: 24px;
    }
  }
  &__organizer_button {
    gap: 8px;
  }
}

.social {
  display: flex;
  align-items: center;
  gap: 24px;
  &__letter {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(166, 174, 203, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(166, 174, 203, 0.12);
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &__link {
    border-radius: 50%;
    transition: all 0.3s ease;
    &:nth-child(2) {
      &:hover {
        transition: all 0.3s ease;
        box-shadow: 0 4px 10px #37aee2;
      }
    }

    &:first-child,
    &:last-child {
      &:hover {
        transition: all 0.3s ease;
        box-shadow: 0 4px 10px #a3a3a3;
      }
    }
  }
}

.text {
  &__bold {
    color: #ebebeb;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-top: 68px;
  }
  &__default {
    display: block;
    margin-top: 24px;
    max-width: 885px;
    width: 100%;
    color: #a3a3a3;
    text-align: center;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    p {
      display: inline-block;
    }
    a {
      display: inline-block;
      color: #ebebeb;

      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      transition: all 0.3 ease;
      &:hover {
        color: #fff;
        transition: all 0.3 ease;
      }
    }
  }
}
